<template>
  <el-dialog
    title="活动阶梯方案"
    :visible.sync="ladderDialogShow"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form :model="formData" :rules="rules" ref="ladderForm" label-width="150px" size="small">
      <el-form-item label="活动阶梯方案名称：" prop="activitySchemeName">
        <el-input
          placeholder="请输入活动阶梯方案名称"
          class="input"
          v-model="formData.activitySchemeName"
          style="width: 360px"
          :maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>

      <el-form-item label="关联城市：" prop="activitySchemeCityList">
        <el-select
          :value="activitySchemeCityIds"
          placeholder="请选择关联城市"
          size="small"
          multiple
          @change="cityChange"
          style="width: 360px"
        >
          <el-option
            v-for="item in cityArr"
            :key="item.cityId"
            :label="item.cityName"
            :value="item.cityId"
            :disabled="item.disabled"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-button
        type="primary"
        size="small"
        style="margin: 0 0 20px 0"
        icon="el-icon-plus"
        @click="addLadderItem"
      >
        添加活动阶梯
      </el-button>

      <el-table :data="formData.ladderData" border style="width: 100%" size="small">
        <el-table-column type="index" label="活动阶梯" width="80"> </el-table-column>
        <el-table-column label="活动门槛（门槛请由小到大）">
          <template slot-scope="scope">
            <el-input-number v-model.number="scope.row.num" :min="0" :max="999999" :precision="2">
            </el-input-number>
            元
          </template>
        </el-table-column>

        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="delLadderItem(scope.$index)"
              v-if="formData.ladderData.length > 1"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>

    <div slot="footer" style="text-align: right">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="submit" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    cityArr: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ladderDialogShow: false,
      formData: {
        activitySchemeName: '',
        activitySchemeDoorsill: '',
        activitySchemeCityList: [],
        ladderData: [],
        cityNames: '',
        index: -1,
      },
      rules: {
        activitySchemeName: { required: true, message: '请输入活动阶梯方案名称', trigger: 'blur' },
        activitySchemeCityList: { required: true, message: '请选择关联城市', trigger: 'change' },
      },
    }
  },
  computed: {
    activitySchemeCityIds() {
      return (this.formData.activitySchemeCityList || []).map((el) => el.cityId)
    },
  },
  watch: {
    item: {
      deep: true,
      handler(newVal) {
        this.$nextTick(() => {
          this.$refs.ladderForm.resetFields()
          this.formData.ladderData = []
          this.formData.index = -1

          if (Object.keys(newVal || {}).length > 0) {
            this.formData = { ...(newVal || {}) }
          }
        })
      },
    },
  },
  methods: {
    submit() {
      this.$refs.ladderForm.validate((valid) => {
        if (!valid) return

        if (this.formData.ladderData.length == 0) {
          this.$message.warning('请添加活动阶梯')
          return
        }

        if (
          this.formData.ladderData.length !=
          [...new Set(this.formData.ladderData.map((el) => el.num))].length
        ) {
          this.$message.warning('活动门槛有重复，请重新编辑')
          return
        }

        this.formData.ladderData.forEach((el) => (el.num = Number(el.num).toFixed(2)))
        this.formData.ladderData.sort((a, b) => a.num - b.num)

        this.formData.activitySchemeDoorsill = this.formData.ladderData
          .map((el) => el.num)
          .join('、')

        this.formData.cityNames = this.formData.activitySchemeCityList
          .map((el) => el.cityName)
          .join('、')

        this.$emit('submit', this.formData)
        this.close()
      })
    },
    open() {
      this.ladderDialogShow = true
    },
    close() {
      this.ladderDialogShow = false
    },
    delLadderItem(index) {
      this.formData.ladderData.splice(index, 1)
    },
    cityChange(e) {
      this.formData.activitySchemeCityList = this.cityArr.filter((el) => e.includes(el.cityId))
    },
    addLadderItem() {
      this.formData.ladderData.push({ num: 0 })
    },
  },
}
</script>

<style></style>
