<template>
  <el-select
    :value="val"
    placeholder="请选择适用城市"
    size="small"
    @change="selectChange"
    :multiple="multiple"
    :disabled="disabled"
  >
    <el-option v-for="item in list" :key="item.cityId" :label="item.cityName" :value="item.cityId">
    </el-option>
  </el-select>
</template>

<script>
import { getServiceCityList } from '@/api/fullGift.js'

export default {
  props: {
    selectVal: {
      type: [String, Number, Array],
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    citylArr: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: [],
      val: '',
    }
  },
  watch: {
    selectVal: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (this.multiple) this.val = [...(newVal || [])]
        else this.val = newVal || ''
      },
    },
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      getServiceCityList({ data: {} }).then((res) => {
        this.list = res.data || []
      })
    },
    selectChange(data) {
      this.val = data
      this.$emit('update:selectVal', data)
      this.$emit('change')

      if (this.multiple) {
        this.$emit(
          'update:cityArr',
          this.list
            .filter((el) => data.includes(el.cityId))
            .map((el) => ({ cityId: el.cityId, cityName: el.cityName })),
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
