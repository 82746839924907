<template>
  <div class="full-gift-edit">
    <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="150px" size="small">
      <div class="form-content">
        <div class="left">
          <h4>基本信息</h4>

          <el-form-item label="活动ID：">
            <el-input
              placeholder="系统自动生成"
              disabled
              class="input"
              v-model="formData.activityId"
            ></el-input>
          </el-form-item>

          <el-form-item label="活动名称：" prop="activityName">
            <el-input
              v-model="formData.activityName"
              placeholder="请输入活动名称"
              class="input"
              :disabled="disabled"
              :maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item label="活动形式：" prop="activityType">
            <el-radio-group v-model="formData.activityType" :disabled="disabled">
              <el-radio :label="0">满赠</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="活动条件：" prop="activityCondition">
            <el-radio-group v-model="formData.activityCondition" :disabled="disabled">
              <el-radio :label="0">满元</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="活动总次数：" prop="quota">
            <el-input-number
              v-model.number="formData.quota"
              :min="1"
              :max="9999999999"
              :precision="0"
              :disabled="disabled"
            ></el-input-number>
          </el-form-item>

          <el-form-item label="单人最大参与次数：" prop="takeLimit">
            <el-input-number
              v-model.number="formData.takeLimit"
              :min="1"
              :max="9999999999"
              :precision="0"
              :disabled="disabled"
            ></el-input-number>
          </el-form-item>

          <el-form-item label="活动开始时间：" prop="activityStartTime">
            <el-date-picker
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="formData.activityStartTime"
              :disabled="disabled"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="活动结束时间：" prop="activityEndType">
            <el-radio-group
              v-model="formData.activityEndType"
              @input="formData.activityEndTime = ''"
              :disabled="disabled"
            >
              <el-radio :label="0">固定时间</el-radio>
              <el-radio :label="1">永久有效</el-radio>
            </el-radio-group>

            <div style="margin-top: 10px" v-if="formData.activityEndType == 0">
              <el-date-picker
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="formData.activityEndTime"
                :disabled="disabled"
              >
              </el-date-picker>
            </div>
          </el-form-item>

          <el-form-item label="活动渠道：" prop="activityChannelList">
            <ChannelSelect
              :select-val="activityChannelIds"
              :channel-arr.sync="formData.activityChannelList"
              multiple
              :disabled="disabled"
            />
          </el-form-item>

          <el-form-item label="礼品选择方式：" prop="prizePickupMethod">
            <el-radio-group v-model="formData.prizePickupMethod" :disabled="disabled">
              <el-radio :label="0">自主选择</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="活动说明：" prop="activityDesc">
            <el-input
              type="textarea"
              placeholder="请输入备注"
              maxlength="300"
              show-word-limit
              :rows="4"
              class="input"
              v-model="formData.activityDesc"
              :disabled="disabled"
            >
            </el-input>
          </el-form-item>
        </div>

        <div class="right">
          <h4>适用城市</h4>

          <el-form-item label="适用城市范围：" prop="activityCityRange">
            <el-radio-group
              v-model="formData.activityCityRange"
              @input="cityChange"
              :disabled="disabled"
            >
              <el-radio :label="0">全部城市</el-radio>
              <el-radio :label="1">部分城市</el-radio>
            </el-radio-group>

            <div style="margin-top: 10px" v-if="formData.activityCityRange == 1">
              <CitySelect
                multiple
                :city-arr.sync="associatedCityArr"
                :select-val="associatedCityIdArr"
                :disabled="disabled"
                @change="rangeCityChange"
              />
            </div>
          </el-form-item>

          <h4>适用产品</h4>

          <el-form-item label="适用产品类型：" prop="activityProductType">
            <el-radio-group v-model="formData.activityProductType" :disabled="disabled">
              <el-radio :label="3">套系产品</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="适用产品范围：" prop="activityProductRange">
            <el-radio-group
              v-model="formData.activityProductRange"
              @input="formData.activityProductList = []"
              :disabled="disabled"
            >
              <el-radio :label="0">全部产品</el-radio>
              <el-radio :label="1">部分品牌产品</el-radio>
            </el-radio-group>

            <div style="margin-top: 10px" v-if="formData.activityProductRange == 1">
              <ProductBrandSelect
                multiple
                :product-brand-arr.sync="formData.activityProductList"
                :select-val="activityProductBrandIdArr"
                :disabled="disabled"
              />
            </div>
          </el-form-item>

          <h4>活动阶梯方案</h4>

          <el-button
            type="primary"
            size="small"
            style="margin: 0 0 20px 0"
            icon="el-icon-plus"
            @click="openLadderDialog"
            v-if="!disabled"
          >
            添加
          </el-button>

          <el-table :data="formData.activitySchemeList" border style="width: 100%" size="small">
            <el-table-column type="index" label="序号" width="50"> </el-table-column>
            <el-table-column prop="activitySchemeName" label="活动阶梯方案名称"> </el-table-column>
            <el-table-column prop="activitySchemeDoorsill" label="活动门槛(元)"> </el-table-column>
            <el-table-column prop="cityNames" label="关联城市"> </el-table-column>

            <el-table-column label="操作" width="120" v-if="!disabled">
              <template slot-scope="scope">
                <el-button size="mini" type="text" @click="processLadderItem(scope.$index, 1)">
                  编辑
                </el-button>

                <el-button size="mini" type="text" @click="processLadderItem(scope.$index, 2)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <LadderDialog
            ref="ladderDialog"
            :city-arr="canUsedCityArr"
            :item="currentActivitySchemeItem"
            @submit="ladderSubmit"
          />

          <h4>礼品配置</h4>

          <el-radio-group size="medium" style="margin-bottom: 20px" v-model="choosedGiftCity">
            <el-radio-button
              :label="item"
              v-for="(item, index) in choosedGiftCityNameArr"
              :key="index"
            ></el-radio-button>
          </el-radio-group>

          <el-table :data="giftData" border style="width: 100%" size="small">
            <el-table-column type="index" label="活动阶梯" width="80"> </el-table-column>
            <el-table-column label="活动门槛">
              <template slot-scope="scope">
                <span>{{ scope.row.doorsillMoney }}元</span>
              </template>
            </el-table-column>

            <el-table-column label="关联活动礼品">
              <template slot-scope="scope">
                <div>已选{{ scope.row.prizeList.length }}个</div>
                <!-- <span>模板：8寸相框、15张底片、拍摄花絮1个</span> -->
                <span>{{ scope.row.prizeList.map((el) => el.prizeName).join('、') }}</span>
              </template>
            </el-table-column>

            <el-table-column label="赠送礼品数量">
              <template slot-scope="scope">
                <el-input-number
                  v-model.number="scope.row.prizeQuantity"
                  :min="1"
                  :max="(scope.row.prizeList || []).length"
                  size="small"
                  controls-position="right"
                  :precision="0"
                  :disabled="disabled"
                ></el-input-number>
              </template>
            </el-table-column>

            <el-table-column label="操作" width="120" v-if="!disabled">
              <template slot-scope="scope">
                <el-button size="mini" type="text" @click="chooseGifts(scope.$index, scope.row)">
                  选择礼品
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <GiftsDialog ref="giftsDialog" :selected-arr="selectedPrizeList" @submit="giftSubmit" />
        </div>
      </div>

      <div class="buttons-wrap">
        <el-button size="small" @click="onSubmit(false)">取 消</el-button>

        <el-button type="primary" size="small" @click="onSubmit(true)" v-if="!disabled">
          确 定
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import CitySelect from './components/CitySelect.vue'
import ProductBrandSelect from './components/ProductBrandSelect.vue'
import LadderDialog from './components/LadderDialog'
import GiftsDialog from './components/GiftsDialog.vue'
import { getFullGiftDetail, saveFullGiftItem } from '@/api/fullGift'
import ChannelSelect from './components/ChannelSelect.vue'
import { getServiceCityList } from '@/api/fullGift.js'

export default {
  components: {
    ProductBrandSelect,
    CitySelect,
    LadderDialog,
    GiftsDialog,
    ChannelSelect,
  },
  data() {
    return {
      formData: {
        activityId: '',
        activityName: '',
        activityType: 0,
        activityCondition: 0,
        quota: 1,
        takeLimit: 1,
        activityStartTime: '',
        activityEndType: 0,
        activityEndTime: '',
        activityChannelList: [],
        prizePickupMethod: 0,
        activityDesc: '',
        activityCityRange: 0,
        activitySchemeList: [],
        activityCityList: [],
        activityProductType: 3,
        activityProductRange: 0,
        activityProductList: [],
      },
      rules: {
        activityName: { required: true, message: '请输入活动名称', trigger: 'blur' },
        activityType: { required: true, message: '请选择活动形式', trigger: 'change' },
        activityCondition: { required: true, message: '请选择活动条件', trigger: 'change' },
        quota: { required: true, message: '请输入活动总次数', trigger: 'blur' },
        takeLimit: { required: true, message: '请输入单人最大参与次数', trigger: 'blur' },
        activityStartTime: { required: true, message: '请选择活动开始时间', trigger: 'change' },
        activityEndType: { required: true, message: '请选择活动结束时间', trigger: 'change' },
        activityChannelList: { required: true, message: '请输入活动活动渠道', trigger: 'change' },
        prizePickupMethod: { required: true, message: '请选择礼品方式', trigger: 'change' },
        activityDesc: { required: true, message: '请输入活动说明', trigger: 'change' },
        activityCityRange: { required: true, message: '请选择适用城市', trigger: 'change' },
        activityProductType: { required: true, message: '请选择适用产品类型', trigger: 'change' },
        activityProductRange: { required: true, message: '请选择适用产品范围', trigger: 'change' },
      },
      associatedCityArr: [],
      currentActivitySchemeItem: {},
      choosedGiftCity: '',
      currentGiftItemIndex: -1,
      editNoRenderGiftOptions: false,
    }
  },
  computed: {
    activityChannelIds() {
      return (this.formData.activityChannelList || []).map((el) => String(el.channelId))
    },
    choosedGiftCityNameArr() {
      return this.associatedCityArr.map((el) => el.cityName)
    },
    giftData() {
      return (this.formData.activityCityList || []).filter((el) => {
        return el.cityName == this.choosedGiftCity
      })
    },
    selectedPrizeList() {
      return (this.formData.activityCityList[this.currentGiftItemIndex] || {}).prizeList || []
    },
    id() {
      return Number(this.$route.query.id || '0')
    },
    canUsedCityArr() {
      const usedCities = [...new Set(this.formData.activityCityList.map((el) => el.cityName))]

      return this.associatedCityArr.map((el) => ({
        ...el,
        disabled: usedCities.includes(el.cityName),
      }))
    },
    associatedCityIdArr() {
      return this.associatedCityArr.map((el) => el.cityId)
    },
    activityProductBrandIdArr() {
      return (this.formData.activityProductList || []).map((el) => el.productBrandId)
    },
    disabled() {
      return new URLSearchParams(window.location.href).get('disabled') == 'true'
    },
  },
  watch: {
    choosedGiftCityNameArr(array) {
      this.choosedGiftCity = array[0] || ''
    },
    'formData.activitySchemeList': {
      deep: true,
      handler(newVal) {
        console.log(newVal)

        if (this.editNoRenderGiftOptions) {
          this.editNoRenderGiftOptions = false
          return
        }

        this.renderGiftOptions()
      },
    },
  },
  created() {
    if (!this.id) {
      this.cityChange()
      return
    }

    this.editNoRenderGiftOptions = true
    this.getDetail()
  },
  methods: {
    submitForm() {
      this.$refs['ruleForm'].validate((valid) => {
        if (!valid) return

        if (this.formData.activityEndType == 0 && !this.formData.activityEndTime) {
          this.$message.warning('请选择活动结束时间')
          return
        }

        if (this.formData.activityCityRange == 1 && this.associatedCityArr.length == 0) {
          this.$message.warning('请选择适用城市')
          return
        }

        if (this.formData.activityProductRange == 1 && !this.formData.activityProductList.length) {
          this.$message.warning('请选择适用品牌')
          return
        }

        if (this.formData.activitySchemeList.length == 0) {
          this.$message.warning('请添加活动阶梯')
          return
        }

        saveFullGiftItem({ data: { ...this.formData } })
          .then((res) => {
            if (res.success) {
              this.$router.back()
              this.$message.success('操作成功')
            }
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    onSubmit(isFlag) {
      if (!isFlag) {
        this.$router.back()
        return
      }

      this.submitForm()
    },
    processLadderItem(index, type) {
      if (type == 1) {
        const json = JSON.parse(
          JSON.stringify({ ...this.formData.activitySchemeList[index], index }),
        )
        this.currentActivitySchemeItem = json
        this.$refs.ladderDialog.open()
      }

      if (type == 2) {
        this.formData.activitySchemeList.splice(index, 1)
      }
    },
    openLadderDialog() {
      this.$refs.ladderDialog.open()
      this.currentActivitySchemeItem = {}
    },
    chooseGifts(_, row) {
      this.currentGiftItemIndex = this.formData.activityCityList.indexOf(row)
      this.$refs.giftsDialog.open()
    },
    getDetail() {
      getFullGiftDetail({ data: this.id }).then((res) => {
        if (Object.keys(res.data || {}).length <= 0) return

        const data = res.data

        data.activitySchemeList &&
          data.activitySchemeList.forEach((el) => {
            el.ladderData = (el.activitySchemeDoorsill || '').split('、').map((el) => ({ num: el }))
            el.cityNames = (el.activitySchemeCityList || []).map((el) => el.cityName).join('、')
          })

        this.formData = { ...data }

        if (data.activityCityRange == 1) {
          const arr = data.activityCityList.map((el) => ({
            cityName: el.cityName,
            cityId: el.cityId,
          }))

          this.associatedCityArr = [...new Set(arr.map(JSON.stringify))].map(JSON.parse)
        }

        if (data.activityCityRange == 0) this.getAllCityList()
      })
    },
    ladderSubmit(item) {
      const copyItem = JSON.parse(JSON.stringify(item))

      if (copyItem.index > -1) {
        this.$set(this.formData.activitySchemeList, copyItem.index, copyItem)
        return
      }

      this.formData.activitySchemeList.push(copyItem)
    },
    renderGiftOptions() {
      const res = []

      this.formData.activitySchemeList.forEach((scheme) => {
        scheme.activitySchemeCityList.forEach(({ cityId, cityName }) => {
          scheme.ladderData &&
            scheme.ladderData.forEach(({ num }) => {
              res.push({
                cityId,
                cityName,
                doorsillMoney: num,
                prizeQuantity: 0,
                prizeList: [],
              })
            })
        })
      })

      this.formData.activityCityList = res
    },
    cityChange() {
      this.associatedCityArr = []
      this.formData.activitySchemeList = []
      this.formData.activityCityList = []
      if (this.formData.activityCityRange > 0) return
      this.getAllCityList()
    },
    giftSubmit(data) {
      const item = this.formData.activityCityList[this.currentGiftItemIndex]
      item.prizeList = [...(data || [])]
      item.prizeQuantity = item.prizeList.length

      this.currentGiftItemIndex > -1 &&
        this.$set(this.formData.activityCityList, this.currentGiftItemIndex, item)

      this.currentGiftItemIndex = -1
    },
    getAllCityList() {
      getServiceCityList({ data: {} })
        .then((res) => {
          this.associatedCityArr = res.data || []
        })
        .catch((err) => console.log(err))
    },
    rangeCityChange() {
      this.formData.activitySchemeList = []
      this.formData.activityCityList = []
    },
  },
}
</script>

<style lang="scss" scoped>
.full-gift-edit {
  background-color: white;
  padding: 0 20px 20px 20px;

  .form-content {
    display: flex;
    background-color: #fff;

    .left {
      flex: 1;
    }

    .right {
      flex: 1;
    }

    .input {
      width: 360px;
    }
  }

  .buttons-wrap {
    text-align: center;
    padding: 20px 0;
    background-color: #fff;
  }
}
</style>
