import request from '@/utils/request'

export function getServiceCityList(data) {
  return request({
    url: 'misc-web-api/api/photo/city/findNotDisableList',
    method: 'POST',
    data,
  })
}

export function getProductBrandList(data) {
  return request({
    url: 'product-web-api/api/productBrand/list',
    method: 'POST',
    data,
  })
}

export function getFullGiftList(data) {
  return request({
    url: 'marketing-web-api/admin/activity/pageList',
    method: 'POST',
    data,
  })
}

export function updateFullGiftItemStatus(data) {
  return request({
    url: 'marketing-web-api/admin/activity/enable',
    method: 'POST',
    data,
  })
}

export function getFullGiftDetail(data) {
  return request({
    url: 'marketing-web-api/admin/activity/detail',
    method: 'POST',
    data,
  })
}

export function saveFullGiftItem(data) {
  return request({
    url: 'marketing-web-api/admin/activity/save',
    method: 'POST',
    data,
  })
}

export function getDictionary(data) {
  return request({
    url: 'misc-web-api/admin/dictionary/getDictionary',
    method: 'POST',
    data,
  })
}

export function getPrizeList(data) {
  return request({
    url: 'marketing-web-api/admin/prize/findListByCondition',
    method: 'POST',
    data,
  })
}
