<template>
  <el-dialog
    title="选择关联礼品"
    width="700px"
    :visible.sync="show"
    @close="close"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form ref="form" inline size="small">
      <el-form-item>
        <el-input placeholder="礼品ID/名称" v-model="searchForm.prizeNoOrName"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
        <el-button @click="reserSearch">重置</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="dataList"
      max-height="400px"
      @selection-change="handleSelectionChange"
      :border="true"
      ref="Table"
      row-key="prizeId"
    >
      <el-table-column type="selection" width="55" reserve-selection> </el-table-column>
      <el-table-column property="prizeNo" label="礼品ID"></el-table-column>
      <el-table-column property="prizeName" label="礼品名称"></el-table-column>
    </el-table>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="onConfirm" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getPrizeList } from '@/api/fullGift'

export default {
  props: {
    selectedArr: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dataList: [],
      searchForm: { prizeNoOrName: '' },
      show: false,
      selected: [],
    }
  },
  watch: {
    selectedArr: {
      deep: true,
      handler(newVal) {
        if (Array.isArray(newVal) && this.dataList.lenght > 0) {
          this.reshowCheckedArr()
        }
      },
    },
    show() {
      if (this.show) {
        this.reserSearch()
      }
    },
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
    },
    onConfirm() {
      this.$emit('submit', this.selected)
      this.close()
    },
    handleSelectionChange(val) {
      this.selected = val || []
    },
    getList() {
      getPrizeList({ data: { isDisable: 0, isInventory: 1, ...this.searchForm } })
        .then((res) => {
          this.dataList = res.data || []
          this.reshowCheckedArr()
        })
        .catch((err) => console.log(err))
    },
    reserSearch() {
      this.searchForm.prizeNoOrName = ''
      this.getList()
    },
    search() {
      this.getList()
    },
    reshowCheckedArr() {
      this.$nextTick(() => {
        this.$refs['Table'].clearSelection()

        this.dataList
          .filter((el) => this.selectedArr.some((d) => d.prizeId == el.prizeId))
          .forEach((el) => {
            this.$refs.Table.toggleRowSelection(el, true)
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

::v-deep .el-dialog__body {
  padding-top: 10px;
}
</style>
