<template>
  <el-select
    :value="val"
    placeholder="请选择适用品牌"
    size="small"
    @change="selectChange"
    :multiple="multiple"
    :disabled="disabled"
  >
    <el-option
      v-for="item in list"
      :key="item.productBrandId"
      :label="item.brandName"
      :value="item.productBrandId"
    >
    </el-option>
  </el-select>
</template>

<script>
import { getProductBrandList } from '@/api/fullGift.js'

export default {
  props: {
    selectVal: {
      type: [String, Number, Array],
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    productBrandArr: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: [],
      val: '',
    }
  },
  watch: {
    selectVal: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (this.multiple) this.val = [...(newVal || [])]
        else this.val = newVal || ''
      },
    },
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      getProductBrandList({ data: {} }).then((res) => {
        const bizLineId = this.$route.query.bizLineId || 1
        const data = res.data || []

        this.list =
          bizLineId == 2
            ? data.filter((el) => el.brandName == 'JPG' || el.brandName == 'jpg')
            : data
      })
    },
    selectChange(data) {
      this.val = data
      this.$emit('update:selectVal', data)

      if (this.multiple) {
        this.$emit(
          'update:productBrandArr',
          this.list
            .filter((el) => data.includes(el.productBrandId))
            .map((el) => ({
              productBrandId: el.productBrandId,
              brandName: el.brandName,
            })),
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
