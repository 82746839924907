<template>
  <el-select
    :value="val"
    placeholder="请选择适用渠道"
    size="small"
    @change="selectChange"
    :multiple="multiple"
    :disabled="disabled"
  >
    <el-option
      v-for="item in list"
      :key="item.dictionaryName"
      :label="item.dictionaryName"
      :value="item.dictionaryValue"
    >
    </el-option>
  </el-select>
</template>

<script>
import { getDictionary } from '@/api/fullGift.js'

export default {
  props: {
    selectVal: {
      type: [String, Number, Array],
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    channelArr: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: [],
      val: '',
    }
  },
  watch: {
    selectVal: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (this.multiple) this.val = [...(newVal || [])]
        else this.val = newVal || ''
      },
    },
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      getDictionary({ data: { dictionaryKey: 'source_from' } }).then((res) => {
        this.list = (res.data || []).filter((el) => {
          return el.dictionaryName.includes('运营') || el.dictionaryName.includes('供应')
        })
      })
    },
    selectChange(data) {
      this.val = data
      this.$emit('update:selectVal', data)

      if (this.multiple) {
        this.$emit(
          'update:channelArr',
          this.list
            .filter((el) => data.includes(el.dictionaryValue))
            .map((el) => ({ channelId: el.dictionaryValue, channelName: el.dictionaryName })),
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
